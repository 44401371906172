<template>
	<div class="publicEmergencies">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline search-form">
				<el-form-item label="市：">
					<el-select v-model="formInline.city" placeholder="请选择市" disabled>
						<el-option v-for="item in []" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="区县：">
					<el-select v-model="formInline.county" placeholder="请选择区县" disabled>
						<el-option v-for="item in []" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="乡镇：">
					<el-select v-model="formInline.townCode" placeholder="请选择">
						<el-option v-for="item in townList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:is-show-selection="false"
				:operation-column-width="140"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo(scope.row)" size="mini" type="primary"> 查看 </el-button>
					<!-- <el-button @click="modifyParkInfo(scope.row)" size="mini" type="primary"> 查看评价 </el-button> -->
				</template>
				<template #showEvaluate="{ scope }">
					<el-button @click="seeEvaluate(scope.row)" type="text"> 查看 </el-button>
				</template>
			</Table>
		</div>
		<DetailsDialog ref="detailsDialog" />
		<ShowEvaluate ref="showEvaluate" />
	</div>
</template>

<script>
export default {
	name: 'healthFollowUp',
	components: {
		Table: () => import('@/components/Table/table'),
		DetailsDialog: () => import('./detailsDialog.vue'),
		ShowEvaluate: () => import('../healthEducation/showEvaluate.vue'),
	},
	data() {
		return {
			loading: false,
			formInline: {
				name: null,
				city: null,
				county: null,
				townCode: null,
			},
			townList: [],
			villageList: [],
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '随访日期',
					prop: 'visitDate',
					formatter: (row) => {
						return row.visitDate || '-';
					},
				},
				{
					label: '随访人员',
					prop: 'visitor',
					formatter: (row) => {
						return row.visitor?.title || '-';
					},
				},
				{
					label: '家庭信息',
					prop: 'household',
					formatter: (row) => {
						return row.household?.title || '-';
					},
				},

				{
					label: '随访人（所属团队）',
					prop: 'healthServiceGroup',
					formatter: (row) => {
						return row.healthServiceGroup?.title || '-';
					},
				},
				{
					label: '联系电话',
					prop: 'phoneNumber',
					formatter: (row) => {
						return row.phoneNumber || '-';
					},
				},
				{
					label: '查看评价',
					prop: 'showEvaluate',
				},
			],
			isShowDetail: false,
			options: [],
			filterLoading: false,
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
		if (userInfo && userInfo.chinaRegionDtoList && userInfo.chinaRegionDtoList.length > 0) {
			this.formInline.city = userInfo.chinaRegionDtoList[1].title;
			this.formInline.county = userInfo.chinaRegionDtoList[2].title;
		}
		this.getList();
		this.getTownList();
	},
	methods: {
		// 获取乡镇/街道
		getTownList() {
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { code: JSON.parse(sessionStorage.getItem('userInfo')).chinaRegionDtoList[2].code } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.townList = res.data.collection || [];
						this.townList.unshift({
							title: '全部',
							code: '',
						});
					}
				})
				.catch((e) => {});
		},
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		onSearch() {
			this.queryData.current = 1;
			this.getList();
		},
		//获取列表
		getList(type = '') {
			type == 'filter' ? (this.filterLoading = true) : (this.loading = true);
			let par = {
				...this.queryData,
				regionCode: this.formInline.townCode ? this.formInline.townCode : null,
			};
			this.$http
				.get(this.api['HouseholdVisits#index'].href, { params: par })
				.then((res) => {
					if (res.data && res.data.success) {
						let collection = res.data.collection || {};
						this.loading = false;
						if (type == 'filter') {
							this.filterLoading = false;
							this.options = collection.map((item) => {
								return {
									...item,
									value: item.eventName,
								};
							});
						} else {
							this.tableData = collection || [];
							this.total = res.data.pagination.totalItems || 0;
						}
					}
				})
				.catch((e) => {});
		},
		modifyParkInfo(row) {
			this.$refs.detailsDialog.init(row);
		},
		seeEvaluate(row) {
			let data = {
				discriminationCode: 'V',
				row,
			};
			this.$refs.showEvaluate.init(data);
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .search-form {
	.el-select {
		width: 120px;
		.el-input {
			width: 120px;
			.el-input__inner {
				width: 120px;
			}
		}
	}
	.el-input {
		width: 160px;
	}
}
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
</style>